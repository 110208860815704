const middleware = {}

middleware['kaikawaji-redirect'] = require('../src/middleware/kaikawaji-redirect.ts')
middleware['kaikawaji-redirect'] = middleware['kaikawaji-redirect'].default || middleware['kaikawaji-redirect']

middleware['language'] = require('../src/middleware/language.ts')
middleware['language'] = middleware['language'].default || middleware['language']

middleware['trailing-slash'] = require('../src/middleware/trailing-slash.ts')
middleware['trailing-slash'] = middleware['trailing-slash'].default || middleware['trailing-slash']

export default middleware
