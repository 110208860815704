import './HSpecialPageView.scss';

import * as tsx from 'vue-tsx-support';
import Vue, { VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { HBtn } from '../HBtn';
import { HSpecialPageLayout } from './HSpecialPageLayout';
import { HBreadCrumbs } from './HBreadCrumbs';
import {
  SearchTagCategoryForGF,
  SpecialPage,
  getMediaDerived,
  Breadcrumb,
} from '~/schemes';
import html2text from '~/helpers/html2text';
import { generateHreflang } from '~/helpers';

export interface HSpecialPageViewProps {
  /** 特集ページ */
  specialPage: SpecialPage;
}

export interface HSpecialPageViewEmits {}

export interface HSpecialPageViewScopedSlots {}

export interface SpHeadSettings {
  specialPage: SpecialPage;
  ogUrl: string;
  headAttrs?: MetaInfo['headAttrs'];
}

export function createSpecialPageHead(
  vm: Vue,
  spHeadSettings: SpHeadSettings,
): MetaInfo {
  const { specialPage, ogUrl, headAttrs } = spHeadSettings;
  const ogImage = getMediaDerived('image', specialPage.ogImage);
  const description = html2text(specialPage.metaDescription).replace(/\n/g, '');
  const title = specialPage.title || '';
  const { isExcludedSearchEngineIndex } = specialPage;
  const { availableLanguages } = specialPage;

  const head: MetaInfo = {
    title,
    meta: [
      { hid: 'og:title', property: 'og:title', content: title },
      {
        hid: 'og:url',
        property: 'og:url',
        content: ogUrl,
      },
    ],
    link: generateHreflang(vm, { availableLanguages }),
  };

  if (ogImage) {
    head.meta!.push({
      hid: 'og:image',
      property: 'og:image',
      content: vm.$res.img(ogImage),
    });
  }
  if (description) {
    head.meta!.push(
      {
        hid: 'description',
        name: 'description',
        content: description,
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: description,
      },
    );
  }
  // 限定プランなどを載せた特集ページのクローラ対策
  if (isExcludedSearchEngineIndex) {
    head.meta!.push({
      hid: 'robots',
      name: 'robots',
      content: 'noindex, nofollow',
    });
  }

  return { ...head, headAttrs };
}

@Component<HSpecialPageViewRef>({
  name: 'HSpecialPageView',
  provide() {
    return {
      specialPageViewRoot: this,
    };
  },

  render(): VNode {
    const {
      isSpecial,
      toSpecialPageLabel,
      isShowBreadcrumb,
      specialPage,
    } = this;

    const { hiddenBookingAction, layout, updatedAt } = specialPage;

    return (
      <div
        staticClass="h-special-page-view"
        class={{
          'h-special-page-view--hidden-booking-action': hiddenBookingAction,
        }}>
        {isShowBreadcrumb && (
          <HBreadCrumbs breadcrumbs={this.resolvedBreadcrumbs} />
        )}
        <HSpecialPageLayout
          layout={layout}
          updatedAt={isSpecial ? updatedAt : undefined}
        />
        {isSpecial && (
          <HBtn
            staticClass="h-special-page-view__action"
            to={`/${this.$language.current}/sp/`}>
            {toSpecialPageLabel}
          </HBtn>
        )}
      </div>
    );
  },
})
export class HSpecialPageViewRef extends Vue implements HSpecialPageViewProps {
  @Prop({ type: Object }) readonly specialPage!: SpecialPage;

  breadcrumbs: Breadcrumb[] | [] = [];

  get isSpecial(): boolean {
    const { type } = this.specialPage;
    if (!type) return false;
    return type === 'expected1000' || type === 'special';
  }

  get toSpecialPageLabel() {
    return this.$language.current === 'ja'
      ? '特集一覧へ'
      : this.$t('action.exploreMore');
  }

  get searchTagCategories(): SearchTagCategoryForGF[] {
    return this.specialPage.searchTagCategories || [];
  }

  /** パンくずリスト表示のboolean */
  get isShowBreadcrumb() {
    return (
      this.specialPage.hierarchySettings &&
      this.specialPage.hierarchySettings.isBreadcrumb
    );
  }

  // パンくずリストを適正化したものを取得
  get resolvedBreadcrumbs(): Breadcrumb[] | [] {
    this.breadcrumbs = this.specialPage.hierarchySettings.breadcrumb.map(
      (i) => {
        return {
          title: i.title,
          slug: this.getResolvedBreadcrumbSlug(i.slug),
        } as Breadcrumb;
      },
    );

    return this.breadcrumbs;
  }

  // パンくずリストのslugを存在するディレクトリに合わせて修正
  private getResolvedBreadcrumbSlug(slug: string): string {
    const { space, target } = this.specialPage;
    const lang = this.$language.current;
    let link = '';

    /** @comment こういう処理をしている場所他にもあるから共通化してもいいかも */
    if (space === 'global') {
      link = `/${lang}/sp/${slug}`;
    }

    if (space === 'brand') {
      const brand = this.$hotel.brands.find(({ id }) => id === target);
      link = `/${lang}/brands/${brand?.slug}/sp/${slug}`;
    }

    if (space === 'hotel') {
      const hotel = this.$hotel.all.find(({ id }) => id === target);
      link = `/${lang}/hotels/${hotel?.slug}/sp/${slug}`;
    }

    return link;
  }
}
export const HSpecialPageView = tsx
  .ofType<
    HSpecialPageViewProps,
    HSpecialPageViewEmits,
    HSpecialPageViewScopedSlots
  >()
  .convert(HSpecialPageViewRef);
