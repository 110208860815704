import './HHotelToBooking.scss';

import * as tsx from 'vue-tsx-support';
import { PropType, VNode } from 'vue';
import { getCustomBookingActionSetting, toInfo } from './utils';
import { HHotelToBookingAction } from './HHotelToBookingAction';
import { HHotelToBookingAir } from './HHotelToBookingAir';
import { HHotelPriceInfo } from '~/components';
import {
  HotelBasicInfo,
  HotelDetail,
  HotelBrandBasicInfo,
  BookingActionSetting,
} from '~/schemes';
import { mergeVNodeData } from '~/helpers';

export const HHotelToBooking = tsx.component({
  name: 'HHotelToBooking',
  functional: true,

  props: {
    hotel: Object as PropType<HotelBasicInfo | HotelDetail | undefined>,
    brand: Object as PropType<HotelBrandBasicInfo | undefined>,
    eventId: String,
  },

  render(h, { data, props, parent }): VNode {
    const { brand, hotel, eventId } = props;

    const { hasLowestPrice, hasBookingLink, dynamicPackage } = toInfo(
      parent,
      props,
    );

    const bookingActionSetting: BookingActionSetting | null =
      parent.$store.getters['bookingActionSettings/current'];

    const customSettings = getCustomBookingActionSetting(bookingActionSetting);

    const { air } = dynamicPackage;

    return (
      <div
        {...mergeVNodeData(data, {
          staticClass: 'h-hotel-to-booking',
          directives: [
            {
              name: 'show',
              value: hasLowestPrice || hasBookingLink,
            },
          ],
        })}>
        {/* @comment リリース後ブランドサイトの金額を非表示にしたい場合はhotelを条件式に追加 */}
        {hasLowestPrice && !customSettings.customUrl && (
          <HHotelPriceInfo
            staticClass="h-hotel-to-booking__price"
            brandSlug={brand && brand.slug}
          />
        )}
        <HHotelToBookingAction
          staticClass="h-hotel-to-booking__action"
          hotel={hotel}
          brand={brand}
          customSettings={customSettings}
          eventId={eventId}
        />
        {!!air && !customSettings.customUrl && (
          <HHotelToBookingAir
            staticClass="h-hotel-to-booking__air"
            href={air}
          />
        )}
      </div>
    );
  },
});
